import {encode as base64_encode} from 'base-64';

export function buildString (obj) {
  var awsAccountName = `opx+${obj.orgLabel}`
  
  return {
    company_label: obj.orgLabel,
    aws_account_name: awsAccountName,
    aws_account_name_base64: base64_encode(awsAccountName),
    callerEmail: obj.callerEmail,
    clinic_info: {
      AutomaticallyCreatedCustomers: [
        {
          Address: obj.orgAddress,
          City: obj.orgCity,
          Description: '',
          Licence: '123456789X8AVAERTY',
          OrganisationName: obj.orgName,
          OrganisationNumber: obj.orgNumber,
          PhoneNumber: obj.orgPhoneNumber,
          Zipcode: obj.orgPostalNumber,
          CustomerNumber: obj.customerNumber
        }
      ]
    }
  }
}
