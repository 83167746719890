/*const awsExports = {
  aws_project_region: 'eu-north-1',
  aws_cognito_region: 'eu-north-1',
  aws_user_pools_id: 'eu-north-1_lR6yKGtR4',
  aws_user_pools_web_client_id: '78s5ukqh832fnehb5mv7f11r8'
}*/

const awsExports = {
  // OPTIONAL - if your API requires authentication
  Auth: {
    // REQUIRED - Amazon Cognito Identity Pool ID
    //identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',
    // REQUIRED - Amazon Cognito Region
    region: 'eu-north-1',
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: 'eu-north-1_pGuaQg9E1',
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: '6gcumkijd5ab2gtqmrk5d97sdk'
  },
  API: {
    endpoints: [
      {
        name: 'oapApi',
        endpoint: 'https://cyz1cu2473.execute-api.eu-north-1.amazonaws.com'
      }
    ]
  }
}
export default awsExports
