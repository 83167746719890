import {
  TextField,
  Button,
  Flex,
  Heading,
  Card,
  Alert
} from '@aws-amplify/ui-react'
import './App.css'
import '@aws-amplify/ui-react/styles.css'
import { Amplify, Auth } from 'aws-amplify'
import { Authenticator } from '@aws-amplify/ui-react'
import { handleSubmitRequest } from './handleSubmitRequest'
import { useState } from 'react'
import React from 'react'

import awsExports from './aws-exports'
Amplify.configure(awsExports)

export default function App () {
  const [orgName, setName] = useState('')
  const [orgLabel, setLabel] = useState('')
  const [orgAddress, setAddress] = useState('')
  const [orgCity, setCity] = useState('')
  const [orgPostalNumber, setPostalNumber] = useState('')
  const [orgPhoneNumber, setPhoneNumber] = useState('')
  const [orgNumber, setNumber] = useState('')
  const [customerNumber, setCustomerNumber] = useState('')
  const [callerEmail, setEmail] = useState('')
  const [isAlertVisible, setAlertVisible] = useState(false)
  const [alertStatus, setAlertStatus] = useState('SUCCESS')
  const [isButtonLoading, setButtonLoading] = useState('')
  const [orgNameError, setOrgLabelError] = useState(false);
  const [orgNameErrorMsg, setOrgLabelErrorMsg] = useState('');
  const [orgNameLoading, setOrgLabelLoading] = useState(false);
  

  function timeout (delay) {
    return new Promise(res => setTimeout(res, delay))
  }

  function clearForm (){
        setName('')
        setLabel('')
        setAddress('')
        setCity('')
        setPostalNumber('')
        setPhoneNumber('')
        setNumber('')
        setEmail('')
        setCustomerNumber('')
    }

    const handleOrgLabelChange = (event) => {
      const newLabel = event.target.value;
      setLabel(newLabel)
      // Only allow letters a-z, digits, and limit length to 3-11 characters
      if (/^[\p{L}\d]{3,11}$/ui.test(newLabel)) {
        setLabel(newLabel);
        setOrgLabelError(false);
      } else {
        setOrgLabelError(true);
        setOrgLabelErrorMsg('Name must be 3-11 letters or digits long.')
      }
    };

    const handleOrgLabelBlur = () => {
      if (!orgNameError) {
        setOrgLabelLoading(true);
        // Call your API validation function here with `name`
        // The function should return a Promise that resolves to either `true` or `false`
        ValidateOrgName(orgName).then((isValid) => {
          setOrgLabelLoading(false);
          isValid && setOrgLabelError(isValid);
          if (!isValid) {
            setOrgLabelErrorMsg('This name is already taken');
          }
        });
      }
    };

  async function ValidateOrgName( orgName ) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(orgName === 'valid');
      }, 1000);
    });
  }

  
  
  async function handleSubmit (obj) {
    if(!orgNameError){
      clearForm()
      setButtonLoading(true)
      // setIsAlertVisible(true)
      await handleSubmitRequest(obj)

      await timeout(300)

      setButtonLoading(false)
      // if no errors are returned,
      setAlertStatus('SUCCESS')
      setAlertVisible(true)

      await timeout(5000)

      setAlertVisible(false)
    }
  }

  // Set the Email state to the users email address so we'll attach it to the API request payload.
  const getAttributes = async () => {
    const { attributes } = await Auth.currentAuthenticatedUser()
    // attributes.email is not available for fresh sessions, therefore we reload the page as a quick fix
    if(!attributes.email){window.location.reload(false)}
    setEmail(attributes.email)
  }

  React.useEffect(() => {
    getAttributes()
  }, [])

  var submitPayload = {
    orgName: orgName,
    orgLabel: orgLabel,
    orgAddress: orgAddress,
    orgCity: orgCity,
    orgPostalNumber: orgPostalNumber,
    orgPhoneNumber: orgPhoneNumber,
    orgNumber: orgNumber,
    callerEmail: callerEmail,
    customerNumber: customerNumber
  }

  return (
    <Authenticator hideSignUp={true}>
      {({ signOut, user }) => (
        <main>
          <div
            style={{
              display: 'flex',
              alignItems: 'right',
              justifyContent: 'right',
              marginTop: '1%',
              marginRight: '1%'
            }}
          >
            <button onClick={signOut}>Sign out</button>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Flex
              style={{ maxWidth: 800, maxHeight: 400 }}
              as='form'
              direction='column'
            >
              <Card variation='outlined'>
                <Heading level={5}>Customer information</Heading>
                <Flex
                  style={{ maxWidth: 800, maxHeight: 400 }}
                  as='form'
                  direction='row'
                >
                  <TextField
                    descriptiveText=''
                    placeholder=''
                    label='Company label'
                    errorMessage={orgNameErrorMsg}
                    isRequired={true}
                    onChange={handleOrgLabelChange}
                    onBlur={handleOrgLabelBlur}
                    value={orgLabel}
                    hasError={orgNameError}
                  />

                  <TextField
                    descriptiveText=''
                    placeholder=''
                    label='Address'
                    errorMessage='There is an error'
                    isRequired={true}
                    onChange={e => setAddress(e.target.value)}
                    value={orgAddress}
                  />
                  <TextField
                    descriptiveText=''
                    placeholder=''
                    label='City'
                    errorMessage='There is an error'
                    isRequired={true}
                    onChange={e => setCity(e.target.value)}
                    value={orgCity}
                  />
                </Flex>
                <Flex
                  style={{ maxWidth: 800, maxHeight: 400 }}
                  as='form'
                  direction='row'
                >
                  <TextField
                    descriptiveText=''
                    placeholder=''
                    label='Postal number'
                    errorMessage='There is an error'
                    isRequired={true}
                    onChange={e => setPostalNumber(e.target.value)}
                    value={orgPostalNumber}
                  />
                  <TextField
                    descriptiveText=''
                    placeholder=''
                    label='Phone number'
                    errorMessage='There is an error'
                    isRequired={true}
                    onChange={e => setPhoneNumber(e.target.value)}
                    value={orgPhoneNumber}
                  />
                  <TextField
                    descriptiveText=''
                    placeholder=''
                    label='Organization number'
                    errorMessage='There is an error'
                    isRequired={true}
                    onChange={e => setNumber(e.target.value)}
                    value={orgNumber}
                  />
                </Flex>
                <Flex
                  style={{ maxWidth: 800, maxHeight: 400 }}
                  as='form'
                  direction='row'
                >
                  <TextField
                    descriptiveText=''
                    placeholder=''
                    label='Organization name'
                    errorMessage='There is an error'
                    isRequired={true}
                    onChange={e => setName(e.target.value)}
                    value={orgName}
                  />
                  <TextField
                    descriptiveText=''
                    placeholder=''
                    label='Customer number'
                    errorMessage='There is an error'
                    isRequired={false}
                    onChange={e => setCustomerNumber(e.target.value)}
                    value={customerNumber}
                  />
                </Flex>
              </Card>
              <Button
                onClick={() => handleSubmit(submitPayload)}
                isLoading={isButtonLoading}
                loadingText='Submitting...'
                variation='primary'
              >
                Submit
              </Button>
              {isAlertVisible ? (
                <Alert
                  variation={
                    alertStatus == 'SUCCESS'
                      ? 'success'
                      : 'error'
                  }
                  hidden={isAlertVisible}
                  heading=''
                >
                  {alertStatus == 'SUCCESS'
                    ? 'Onepix Cloud instance is being created. An e-mail will be sent when the instance is ready to use.'
                    : 'Error'}
                </Alert>
              ) : null}
            </Flex>
          </div>
        </main>
      )}
    </Authenticator>
  )
}
