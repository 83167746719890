import { API, Auth } from 'aws-amplify'
import { buildString } from './stringBuilder'

export async function handleSubmitRequest (obj) {
  const response = await API.post('oapApi', '/prod/submit', {
    body: buildString(obj),
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
      'Content-Type': 'application/json'
    }
  })

  return response
}
